import React from "react";
import Sidenav from "../../vendor/libs/sidenav";
import { t } from "ttag";

export default function SideNav(props) {
  function layoutSidenavClasses() {
    return props.position !== "horizontal"
      ? "layout-sidenav"
      : "layout-sidenav-horizontal bg-dark container-p-x flex-grow-0";
  }

  return (
    <div className="ui-block">
      <div className="cui-example h-100">
        <Sidenav
          orientation={props.position}
          className={layoutSidenavClasses()}
        >
          <div className={`sidenav-inner ${props.position !== "horizontal" ? "py-1 bg-dark" : ""}`}>
            {/**Cadastros */}
            <Sidenav.Menu
              icon="lnr lnr-book icon-sideNav"
              linkText={t`Cadastros`}
              open={true}
            >
            <Sidenav.RouterLink to="/cadastros/monitor-importacao">
              {t`Monitor de importação`}
            </Sidenav.RouterLink>
              <Sidenav.RouterLink to="/cadastros/rpa-logs-execucao">
                {t`Rpa - Logs de execução`}
              </Sidenav.RouterLink>
            </Sidenav.Menu>

            {/**Dashboard */}
            <Sidenav.Menu
              icon="lnr lnr-pie-chart icon-sideNav"
              linkText={t`Dashboard`}
              open={true}
            >
              <Sidenav.RouterLink to="/dashboard">
                {t`News`}
              </Sidenav.RouterLink>
              <Sidenav.RouterLink to="/dashboard/clientes">
                {t`Clientes`}
              </Sidenav.RouterLink>
              <Sidenav.RouterLink to="/dashboard/ativos">
                {t`Ativos`}
              </Sidenav.RouterLink>
              <Sidenav.RouterLink to="/dashboard/faturamento">
                {t`Faturamento`}
              </Sidenav.RouterLink>
              <Sidenav.RouterLink to="/dashboard/controlecontas">
                {t`Controle de Contas`}
              </Sidenav.RouterLink>
            </Sidenav.Menu>

            {/**Billing */}
            <Sidenav.Menu
              icon="lnr lnr-earth icon-sideNav"
              linkText={t`Billing`}
              open={true}
            >
              <Sidenav.Menu linkText={t`Faturamento`} open={false}>
                {/* <Sidenav.RouterLink to="/billing/faturamento/cliente-servico-cnpj">
                  {t`CNPJ por cliente/serviço`}
                </Sidenav.RouterLink> */}
                <Sidenav.RouterLink to="/billing/faturamento/catalogo-tarifa">
                  {t`Catálogo tarifas`}
                </Sidenav.RouterLink>
                <Sidenav.RouterLink to="/billing/faturamento/regra-faturamento">
                  {t`Regras de faturamento`}
                </Sidenav.RouterLink>
                <Sidenav.RouterLink to="/billing/faturamento/fatura-detalhe-servico">
                  {t`Faturamento de serviços`}
                </Sidenav.RouterLink>
              </Sidenav.Menu>
              <Sidenav.Menu linkText={t`Detalhamento`} open={false}>
                <Sidenav.Menu linkText={t`Ativos`} open={true}>
                  <Sidenav.RouterLink to="/billing/detalhamento/ativos/arquivos">
                    {t`Arquivos`}
                  </Sidenav.RouterLink>
                  <Sidenav.RouterLink to="/billing/detalhamento/ativos/detalhes">
                    {t`Detalhes`}
                  </Sidenav.RouterLink>
                </Sidenav.Menu>
              </Sidenav.Menu>
              <Sidenav.RouterLink to="/billing/prefatura">
                {t`Pré-fatura`}
              </Sidenav.RouterLink>
              <Sidenav.RouterLink to="/billing/operadora">
                {t`Operadora`}
              </Sidenav.RouterLink>
              <Sidenav.RouterLink to="/billing/grupo-de-servicos">
                {t`Grupo de Serviços`}
              </Sidenav.RouterLink>
              <Sidenav.RouterLink to="/billing/servico-operadora">
                {t`Serviço Operadora`}
              </Sidenav.RouterLink>
              <Sidenav.RouterLink to="/billing/perfil-cliente">
                {t`Perfil do Cliente`}
              </Sidenav.RouterLink>
              <Sidenav.RouterLink to="/billing/fatura-imposto">
                {t`Fatura Imposto`}
              </Sidenav.RouterLink>
                <Sidenav.RouterLink to="/billing/fatura-consumo">
                  {t`Fatura consumo`}
                </Sidenav.RouterLink>
            </Sidenav.Menu>

            {/*Inventario*/}
            <Sidenav.Menu
              icon="lnr lnr-flag icon-sideNav"
              linkText={t`Inventario`}
              open={true}
            >
              {/* <Sidenav.Menu linkText={t`Inventário`} open={true}>
                <Sidenav.RouterLink to="/inventario/ativo">
                  {t`Ativos`}
                </Sidenav.RouterLink>
                <Sidenav.RouterLink to="/inventario/tipo-ativo">
                  {t`Tipos de ativo`}
                </Sidenav.RouterLink>
              </Sidenav.Menu> */}

              <Sidenav.RouterLink to="/inventario/tipo-ativo">
                {t`Inventário`}
              </Sidenav.RouterLink>
              <Sidenav.RouterLink to="/inventario/usuarios">
                {t`Cadastro de Usuários`}
              </Sidenav.RouterLink>
              <Sidenav.RouterLink to="/inventario/backoffice">
                {t`Backoffice`}
              </Sidenav.RouterLink>
            </Sidenav.Menu>

            {/**Relatorios */}
            <Sidenav.Menu
              icon="lnr lnr-screen icon-sideNav"
              linkText={t`Relatórios`}
              open={true}
            >
              <Sidenav.RouterLink to="/relatorios">
                {t`Relatórios`}
              </Sidenav.RouterLink>
            </Sidenav.Menu>

            {/**Financeiro */}
            <Sidenav.Menu
              icon="lnr lnr-diamond icon-sideNav"
              linkText={t`Financeiro`}
              open={true}
            >
              <Sidenav.Menu linkText={t`Contratos`} open={true}>
                <Sidenav.RouterLink to="/financeiro/contrato/documento">
                  {t`Documentos`}
                </Sidenav.RouterLink>
                <Sidenav.RouterLink to="/financeiro/contrato/conta">
                  {t`Contas`}
                </Sidenav.RouterLink>
                <Sidenav.RouterLink to="/financeiro/contrato/fatura">
                  {t`Faturas`}
                </Sidenav.RouterLink>
              </Sidenav.Menu>
            </Sidenav.Menu>

            {/**Configurações */}
            <Sidenav.Menu
              icon="lnr lnr-magic-wand icon-sideNav"
              linkText={t`Configurações`}
              open={true}
            >
              {/*<Sidenav.RouterLink to="/configs/ativo/filtro-campo">
                {t`Ger. Filtro Campo - [Ativo]`}
              </Sidenav.RouterLink>
               <Sidenav.Menu linkText={t`Permissionamentos`} open={false}>
                <Sidenav.RouterLink to="/configs/ativo/permissionamento">
                  {t`Ativo`}
                </Sidenav.RouterLink>
                <Sidenav.RouterLink to="/configs/tipo-ativo/permissionamento">
                  {t`Tipo Ativo`}
                </Sidenav.RouterLink>
                <Sidenav.RouterLink to="/configs/tipo-ativo/props/permissionamento">
                  {t`Tipo Ativo - [Propriedades/Valores]`}
                </Sidenav.RouterLink>
              </Sidenav.Menu> 
              <Sidenav.Menu linkText={t`Extensões`} open={false}>
                <Sidenav.RouterLink to="/configs/extensoes/estoque-minimo">
                  {t`[Ativos] Estoque mínimo`}
                </Sidenav.RouterLink>
              </Sidenav.Menu>*/}
              <Sidenav.RouterLink to="/configs/webhooks">
                {t`Webhooks`}
              </Sidenav.RouterLink>
              <Sidenav.RouterLink to="/configs/operadora-logins">
                {t`Operadora - Logins`}
              </Sidenav.RouterLink>
              <Sidenav.Menu linkText={t`Conta`}>
                <Sidenav.RouterLink to="/configs/conta/contaxreferencia">
                  {t`Conta X Referencia`}
                </Sidenav.RouterLink>
              </Sidenav.Menu>
            </Sidenav.Menu>

            {/**BTBm */}
            <Sidenav.Menu
              icon="lnr lnr-layers icon-sideNav"
              linkText={t`BTBm`}
              open={true}
            >
              <Sidenav.Menu linkText={t`Cadastro`}>
                <Sidenav.RouterLink to="/btbm/extracaodados/extracaodados">{t`Tabela dinâmica`}</Sidenav.RouterLink>
              </Sidenav.Menu>
              <Sidenav.Menu linkText={t`Cliente`}>
                <Sidenav.RouterLink to="/btbm/cliente/cliente">{t`Cliente`}</Sidenav.RouterLink>
                <Sidenav.RouterLink to="/btbm/cliente/perfilcliente">{t`Perfil`}</Sidenav.RouterLink>
              </Sidenav.Menu>
              <Sidenav.Menu linkText={t`Operadora`}>
                <Sidenav.RouterLink to="/btbm/operadora/conta">{t`Conta`}</Sidenav.RouterLink>
                <Sidenav.RouterLink to="/btbm/operadora/fatura">{t`Fatura`}</Sidenav.RouterLink>
              </Sidenav.Menu>
              <Sidenav.RouterLink to="/btbm/telefone/telefone">{t`Linhas`}</Sidenav.RouterLink>
              <Sidenav.Menu linkText={t`Detalhe`}>
                <Sidenav.RouterLink to="/btbm/detalhamento/importacao">{t`Importação`}</Sidenav.RouterLink>
                <Sidenav.RouterLink to="/btbm/detalhamento/faturadetalhe">{t`Exportação`}</Sidenav.RouterLink>
                <Sidenav.RouterLink to="/btbm/detalhamento/faturaarquivo">{t`Controle de importação`}</Sidenav.RouterLink>
                <Sidenav.RouterLink to="/btbm/detalhamento/processarpdf">{t`Processar PDF`}</Sidenav.RouterLink>
              </Sidenav.Menu>
              <Sidenav.Menu linkText={t`Faturamento`}>
                <Sidenav.RouterLink to="/btbm/faturamento/faturamento">{t`Controle de faturamento`}</Sidenav.RouterLink>
                <Sidenav.RouterLink to="/btbm/faturamento/transferencia">{t`Transferência`}</Sidenav.RouterLink>
                <Sidenav.RouterLink to="/btbm/faturamento/faturar">{t`Faturar`}</Sidenav.RouterLink>
                <Sidenav.RouterLink to="/btbm/faturamento/enviaremailliberacaoteste">{t`Envio de e-mail teste de liberação de fat.`}</Sidenav.RouterLink>
              </Sidenav.Menu>
            </Sidenav.Menu>

            {/**Catálogo */}
            {/* <Sidenav.Menu icon="lnr lnr-book" linkText={t`Catálogo`} open={true}>
              <Sidenav.RouterLink to="/catalogo">Catálogo</Sidenav.RouterLink>
            </Sidenav.Menu> */}
          </div>
        </Sidenav>
      </div>
    </div>
  );
}
